import React from 'react'
import styles from './marketing.module.scss'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import SEO from '../components/common/seo'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { CLOCK, LIKE, MOBILE } from '../components/icons'

const Section = props => (
  <div className="text-left">
    <h2 className={styles.privacy_subtitle}>{props.article}</h2>
    <p className={styles.privacy_item}>{props.description}</p>
  </div>
)
const Privacy = ({ data }) => {
  const [benefits] = [data.datoCmsPrivacyPage.benefit.benefits]
  benefits[0].icon = <MOBILE />
  benefits[1].icon = <CLOCK />
  benefits[2].icon = <LIKE />
  return (
    <div>
      <SEO />
      <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
      <Layout>
        <div className="row bg-primary">
          <div className="col-12">
            <div className={`${styles.content} bg-white mb-5`}>
              <div className="about">
                <hr className={styles.about_special} />
                <h2 className={styles.about_title}>{data.datoCmsPrivacyPage.title}</h2>
              </div>
              <div className="privacy">
                {data.datoCmsPrivacyPage.policy.map((privacy, index) => (
                  <Section article={privacy.article} description={privacy.description} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Privacy

export const query = graphql`
  query PrivacyQuery {
    datoCmsPrivacyPage {
      title
      policy {
        id
        article
        description
      }
      benefit {
        benefits {
          id
          title
          description
        }
      }
    }
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
  }
`
